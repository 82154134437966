




























import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import Employee from '@/calendesk/models/DTO/Response/Employee'

export default mixins(DraftElement).extend({
  name: 'EmployeeCard',
  props: {
    employee: {
      type: Employee
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})
