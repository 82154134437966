import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import { mapActions, mapGetters } from 'vuex'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import SectionConfigurationEmployee from '@/calendesk/models/SectionConfigurationEmployee'

export default mixins(Section).extend({
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employee/getEmployees'
    }),
    list (): Employee[] {
      if (this.employees) {
        const selectedEmployeeIds = this.data.configuration.wb_employee_list__employees__.map((item: SectionConfigurationEmployee) => item.id)
        return this.employees.filter((employee: Employee) => selectedEmployeeIds.includes(employee.id))
      }

      return []
    }
  },
  mounted () {
    if (!this.employees) {
      this.loading = true
      this.fetchEmployees().finally(() => { this.loading = false })
    }
  },
  methods: {
    ...mapActions({
      fetchEmployees: 'employee/fetch'
    }),
    generateSocialMediaList (employee: Record<string, any>): Array<Record<string, any>> {
      const result = []
      if (employee.youtube) result.push({ href: employee.youtube, icon: '$yt' })
      if (employee.facebook) result.push({ href: employee.facebook, icon: '$fb' })
      if (employee.instagram) result.push({ href: employee.instagram, icon: '$instagram' })
      if (employee.twitter) result.push({ href: employee.twitter, icon: '$twitter' })
      return result
    }
  }
})
